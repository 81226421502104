import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DB } from 'src/auth/context/firebase/auth-provider';

import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';

import { useAuthContext } from 'src/auth/hooks';

import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Logo from 'src/components/logo';
import { doc, getDoc } from 'firebase/firestore';

import { PATH_AFTER_LOGIN } from 'src/config-global';
import { get } from 'lodash';

// ----------------------------------------------------------------------

export default function FirebaseInviteView() {
  const { register, loginWithGoogle, loginWithGithub, loginWithTwitter, registerCourse, loginWithGoogleCourse } = useAuthContext();

  const [errorMsg, setErrorMsg] = useState('');
  const [inviteDetails, setInviteDetails] = useState(null);

  const router = useRouter();

  const password = useBoolean();

  const url = window.location.href;
  const inviteToken = url.split('/').pop();
  console.log(inviteToken);

  // Fetch invite details from Firestore
  useEffect(() => {
    const inviteRef = doc(DB, 'invitations', inviteToken);
    const fetchInviteDetails = async () => {
      const doc = await getDoc(inviteRef);
      if (doc.exists) {
        setInviteDetails(doc.data());
      } else {
        setErrorMsg('Invalid or expired invite.');
      }
    };

    fetchInviteDetails();
  }, [inviteToken]);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: inviteDetails?.email || '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await registerCourse?.(data.email, data.password, data.firstName, data.lastName, inviteToken, inviteDetails?.teamId, inviteDetails?.role);
      const searchParams = new URLSearchParams({
        email: data.email,
      }).toString();

      const href = `${paths.auth.firebase.verify}?${searchParams}`;

      router.push(href);
    } catch (error) {
      console.error(error);
      reset();
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const handleGoogleLogin = async () => {
    try {
      await loginWithGoogleCourse?.(inviteToken, inviteDetails?.teamId, inviteDetails?.role);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGithubLogin = async () => {
    try {
      await loginWithGithub?.();
    } catch (error) {
      console.error(error);
    }
  };

  const handleTwitterLogin = async () => {
    try {
      await loginWithTwitter?.();
    } catch (error) {
      console.error(error);
    }
  };

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
      <Logo sx={{ paddingBottom: 2, alignSelf: 'center' }} />

      <Typography sx={{ alignSelf: 'center' }} variant="h4">Sign up with invite link.</Typography>

      <Stack direction="row" spacing={0.5} sx={{ alignSelf: 'center' }}>
        <Typography variant="body2"> Already have an account? </Typography>

        <Link href={paths.auth.firebase.login} component={RouterLink} variant="subtitle2">
          Sign in
        </Link>
      </Stack>
    </Stack>
  );

  const renderTerms = (
    <Typography
      component="div"
      sx={{
        mt: 2.5,
        textAlign: 'center',
        typography: 'caption',
        color: 'text.secondary',
      }}
    >
      {'By signing up, I agree to '}
      <Link underline="always" color="text.primary">
        Terms of Service
      </Link>
      {' and '}
      <Link underline="always" color="text.primary">
        Privacy Policy
      </Link>
      .
    </Typography>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <RHFTextField name="firstName" label="First name" />
        <RHFTextField name="lastName" label="Last name" />
      </Stack>

      <RHFTextField name="email" label="Email address" />

      <RHFTextField
        name="password"
        label="Password"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Create account
      </LoadingButton>
    </Stack>
  );

  const renderLoginOption = (
    <div>

      <Stack direction="row" justifyContent="center" spacing={2}>
        <Button
          onClick={handleGoogleLogin}
          variant="outlined"
          startIcon={<Iconify icon="eva:google-fill" width={24} height={24} />}
          sx={{
            bgcolor: 'background.paper', // Add a background color
            color: '#DF3E30', // Set the color of the text to Google's brand color
            boxShadow: 1, // Add shadow for better visibility
            '&:hover': {
              bgcolor: 'grey.300', // Change background on hover
            }
          }}
        >
          Sign in with Google
        </Button>

        {/* <IconButton color="inherit" onClick={handleGithubLogin}>
          <Iconify icon="eva:github-fill" />
        </IconButton>

        <IconButton onClick={handleTwitterLogin}>
          <Iconify icon="eva:twitter-fill" color="#1C9CEA" />
        </IconButton> */}
      </Stack>

      <Divider
        sx={{
          my: 2.5,
          typography: 'overline',
          color: 'text.disabled',
          '&:before, :after': {
            borderTopStyle: 'dashed',
          },
        }}
      >
        OR
      </Divider>
    </div>
  );

  return (
    <>
      {renderHead}

      {!!errorMsg && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {errorMsg}
        </Alert>
      )}

      {renderLoginOption}

      <FormProvider methods={methods} onSubmit={onSubmit}>
        {renderForm}
      </FormProvider>

      {renderTerms}

    </>
  );
}
