import React, { createContext, useState, useContext, useEffect } from 'react';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuthContext } from 'src/auth/hooks';

// Create the context
const ContentProgressContext = createContext();

// Provider component
export const ContentProgressProvider = ({ children }) => {
  const [currentContentId, setCurrentContentId] = useState(null);
  const [currentProgressId, setCurrentProgressId] = useState(null);
  const { user } = useAuthContext();
  const userId = user?.uid;

  useEffect(() => {
    const fetchCurrentContentId = async () => {
      if (!userId) return;
      const db = getFirestore();
      const userRef = doc(db, 'users', userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        setCurrentContentId(userSnap.data().currentContentId);
        console.log('Fetched current content ID:', userSnap.data().currentContentId); // Add console log here
      }
    };

    fetchCurrentContentId();
  }, [userId]);

  const updateCurrentContentId = async (contentId, currentProgressId) => {
    if (!userId) return;
    const db = getFirestore();
    const userRef = doc(db, 'users', userId);

    console.log('Updating current content ID:', contentId); // Add console log here

    await updateDoc(userRef, {
      currentContentId: contentId,
      currentProgress: currentProgressId,
    });

    setCurrentContentId(contentId);
    setCurrentProgressId(currentProgressId);
  };

  return (
    <ContentProgressContext.Provider value={{ currentContentId, currentProgressId, updateCurrentContentId }}>
      {children}
    </ContentProgressContext.Provider>
  );
};

// Custom hook to use the ContentProgressContext
export const useContentProgress = () => {
  return useContext(ContentProgressContext);
};
